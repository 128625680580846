var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"v-creation-wrapper"},[_c('div',{staticClass:"wrapper-header"},[_c('VPageHeader',{staticClass:"header"},[_c('div',[_c('VBreadcrumbs',{attrs:{"current-route":_vm.currentRoute,"routes":_vm.routes}}),_c('div',{staticClass:"wrapper-title"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('PMChartStatus',{attrs:{"chart-status":_vm.status,"statuses":_vm.statuses}})],1)],1)]),_c('div',{staticClass:"action"},[(_vm.approvalVisible)?_c('VButton',{attrs:{"disabled":!_vm.approvalEnabled},on:{"click":_vm.actionHandler}},[_vm._v(" "+_vm._s(_vm.actionTitle)+" ")]):_vm._e(),(_vm.actionWithDocVisible)?_c('VButton',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideActionsDropdown),expression:"hideActionsDropdown"}],attrs:{"disabled":!_vm.actionWithDocEnabled},on:{"click":_vm.toggleActionsDropdown}},[_vm._v(" Действия с документом "),_c('span',{staticClass:"designing-editor__droplist",class:{ 'designing-editor__droplist--visible': _vm.isActionsDropdownOpen }},_vm._l((_vm.droplistItems),function(link,idx){return _c('a',{key:idx,attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return link.actionHandler.apply(null, arguments)}}},[_vm._v(" "+_vm._s(link.label)+" ")])}),0)]):_vm._e(),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('history'),
                    placement: 'bottom',
                    defaultHideOnTargetClick: false,
                    autoHide: false,
                    classes: 'wrapper-icon-tooltip',
                }),expression:"{\n                    content: $t('history'),\n                    placement: 'bottom',\n                    defaultHideOnTargetClick: false,\n                    autoHide: false,\n                    classes: 'wrapper-icon-tooltip',\n                }"}],staticClass:"wrapper-icon",on:{"click":_vm.showHistory}},[_c('VButtonIcon',{attrs:{"color":"green"}},[_c('HistorySVG')],1)],1)],1)],1),_vm._t("default"),_c('div',{staticClass:"margin-bottom"}),_c('PMChartHistory',{ref:"chartHistory",attrs:{"history-type":_vm.PMTabTypeEnum.weeklyReport}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }